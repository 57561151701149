<template>
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'" v-loading="loadingData">
        <div class="deal-task-tracker" >
            
            <div class="card-table-body ifon-add-title-block">
                <el-row>
                    <el-col :span="12">
                        <div class="content-in__title filter-title">
                            <h1 class="content-title mr-4" :class="mode ? 'content__titleday' : 'content__titlenight'">{{ $t('message.task') }}</h1>
                            <el-form ref="form" :model="filterForm" class="d-flex el-form-item-0">
                                <el-form-item v-if="authUser.is_super_admin">
                                    <select-user
                                        :size="'medium'"
                                        :placeholder="$t('message.user')"
                                        :id="filterForm.user_id"
                                        :class="mode ? 'input__day' : 'input__night'"
                                        :task_users="true"
                                        v-model="filterForm.user_id"
                                    >
                                    </select-user>
                                </el-form-item>

                                <el-form-item>
                                    <select-filial
                                        :size="'medium'"
                                        :placeholder="$t('message.filial')"
                                        :id="filterForm.filial_id"
                                        v-model="filterForm.filial_id"
                                        :class="mode ? 'input__day' : 'input__night'"

                                    >
                                    </select-filial>
                                </el-form-item>
                                <el-form-item>
                                    <select-filter
                                        :size="'medium'"
                                        :placeholder="$t('message.filter')"
                                        :id="filterForm.label_ids"
                                        :class="mode ? 'input__day' : 'input__night'"
                                        v-model="filterForm.label_ids"
                                    >
                                    </select-filter>
                                </el-form-item>

                            </el-form>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="content__setting">
                            <div class="custom__button ml-3" @click="newTaskBoard()" v-if="permissions.some(per => per.slug == 'taskBoards.create')">
                                <el-button type="primary" icon="el-icon-s-order" size="medium" >
                                    {{ $t('message.create_board') }}
                                </el-button>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                
                
            </div>

            <div class="tasks__relative">
                <div class="tasks__absolute">
                    <div class="deal-track-wrap mt-5 p-3" :class="mode ? 'scrollx__day' : 'scrollx__night'">
                        <draggable v-model="rows" @change="changeBoardIndex" class="d-flex">
                            <div
                            class="deal-track-items rounded hidden"
                            :class="mode ? 'deal__backday' : 'deal__backnight'"
                            v-for="row in rows"
                            :key="row.index"
                            >
                            <div
                                class="deal-track-header content-title content-title--medium p-3 d-flex f-between align-center"
                                :style="{ background: row.color }"
                            >
                                <span class="d-block w-100 pointer">
                                {{ row.name }}
                                </span>
                                <div
                                class="save-changes mx-3 pointer transition"
                                >
                                <i class="el-icon-success"></i>
                                </div>
                                <div class="track-header-menu pointer">
                                <el-dropdown trigger="click">
                                    <span class="el-dropdown-link">
                                    <i
                                        class="el-icon-more-outline text-white"
                                        style="font-size: 18px"
                                    ></i>
                                    </span>
                                    <el-dropdown-menu
                                    slot="dropdown"
                                    class="task-track-dropdown"
                                    >
                                    <el-dropdown-item v-if="authUser.is_super_admin && permissions.some(per => per.slug == 'taskBoards.update')">
                                        <el-button
                                        type="text"
                                        icon="el-icon-edit"
                                        size="medium"
                                        @click="editTaskTitle(row)"
                                        >{{ $t('message.update') }}</el-button
                                        >
                                    </el-dropdown-item>
                                    <el-dropdown-item v-if="authUser.is_super_admin && permissions.some(per => per.slug == 'taskBoards.delete')">
                                        <el-button
                                        type="text"
                                        icon="el-icon-delete"
                                        size="medium"
                                        @click="destroy(row)"
                                        >
                                        {{ $t('message.delete') }}
                                        </el-button>
                                    </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                                </div>
                            </div>

                            <div :class="mode ? 'plus__day' : 'plus__night'" class="add-task" @click="openDrawer(row.id)" v-if="permissions.some(per => per.slug == 'tasks.create')">
                                <i class="el-icon-circle-plus-outline"></i>
                            </div>

                                <div class="deal-track-body p-3 rounded" :class="mode ? 'overflow__day' : 'overflow__night'">
                                <draggable
                                    class="list-group"
                                    :list="row.tasks"
                                    :group="{ name: 'row' }"
                                    @change="changeTaskIndex($event, row)"
                                    v-bind="dragOptions"
                                    @start="drag = true"
                                    @end="drag = false"
                                >
                                    <transition-group
                                    tag="p"
                                    type="transition"
                                    :name="!drag ? 'flip-list' : null"
                                    >
                                    <li
                                        class="list-group-item"
                                        v-for="element in row.tasks"
                                        :key="element.id"
                                        type="primary"
                                        @dblclick="openTaskUpdate(element)"
                                    >
                                        <div class="deal-task-items p-3 rounded mb-3" :class="mode ? 'tasks__day' : 'tasks__night'">
                                        <div class="metca"></div>

                                        <div class="d-flex f-between mb-2">
                                            <span :class="mode ? 'text__day2' : 'text__white'" class="nomer-deal-task">
                                                <span>№</span>
                                                {{ element.id }}
                                            </span>
                                            <div :class="mode ? 'text__day2' : 'text__white'" class="name-tesk">
                                                {{ element.name }}
                                            </div>
                                        </div>
                                        <div :class="mode ? 'text__day2' : 'text__night1'" class="task-text d-flex f-between mb-3" v-if="element.deal_id">
                                            {{ $t('message.deal') }}: <span> </span> №{{ element.deal_id }}
                                        </div>
                                        <div :class="mode ? 'text__day2' : 'text__night1'" class="task-text d-flex f-between mb-3" v-else>
                                            {{ $t('message.without_deals') }}
                                        </div>

                                        <div class="d-flex f-between mb-3">
                                        <div class="users__info">
                                        <div class="task_user_give">
                                            <div class="itme-userss">
                                                <el-popover
                                                    placement="top"
                                                    popper-class="p-0 myir"
                                                    width="300"
                                                    trigger="hover">
                                                    <div class="click-block">
                                                        <div class="top-u-click">
                                                            <img class="task_user_give_img" :src="element.user.avatar ? element.user.avatar : '/img/flag/employee.svg'" alt="">
                                                            <div class="tt-name">
                                                                <div>{{ element.user.name }}</div>
                                                                <span>{{ element.user.email ?  element.user.email : ''}}</span>
                                                            </div>
                                                        </div>
                                                        <ul>
                                                            <li>
                                                                <span><i class="el-icon-warning-outline"></i>ID: {{ element.user.id }}</span>
                                                            </li>
                                                            <li>
                                                                <span><i class="el-icon-mobile-phone"></i>Tel: {{ element.user.phone }}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    
                                                    <el-button slot="reference">
                                                        <div class="task_user_give_names"><img class="task_user_give_img" :src="element.user.avatar ? element.user.avatar : '/img/flag/employee.svg'" alt=""></div>
                                                    </el-button>
                                                </el-popover>
                                            </div>
                                        </div>
                                        <i :class="mode ? 'text__day2' : 'text__night1'" class="el-icon-arrow-right ml-1 mr-1" v-if="element.participants && element.participants.length > 0"></i>
                                        <div class="d-flex task_user_accept">
                                            <div class="itme-userss">
                                                <el-popover
                                                    placement="top"
                                                    popper-class="p-0 myir"
                                                    width="300"
                                                    v-for="participant in element.participants" :key="participant.id"
                                                    trigger="hover">
                                                    <div class="click-block">
                                                        <div class="top-u-click">
                                                            <img class="task_user_give_img" :src="participant.avatar ? participant.avatar : '/img/flag/employee.svg'" alt="">
                                                            <div class="tt-name">
                                                                <div>{{ participant.name }}</div>
                                                                <span>{{ participant.email ? participant.email : ''}}</span>
                                                            </div>
                                                        </div>
                                                        <ul>
                                                            <li>
                                                                <span><i class="el-icon-warning-outline"></i>ID: {{ participant.id }}</span>
                                                            </li>
                                                            <li>
                                                                <span><i class="el-icon-mobile-phone"></i>Tel: {{ participant.phone }}</span>
                                                            </li>
                                                        </ul>
                                                        
                                                    </div>
                                                    
                                                    <el-button slot="reference">
                                                        <img class="task_user_give_img" :src="participant.avatar ? participant.avatar : '/img/flag/employee.svg'" alt="">
                                                    </el-button>
                                                </el-popover>
                                            </div>
                                        </div>
                                        </div>
                                        </div>
                                        <div class="task-term" >
                                            <div class="d-flex align-center">
                                                <span v-if="element.begin_date" class="data-deal-task-end">
                                                <i class="el-icon-time"></i>
                                                {{ formatDate(element.begin_date) }}
                                                </span>
                                                <span v-if="element.begin_date" class="data-deal-task-start"
                                                :class="dayDiff(element) >= 0 ? 'coloa2' : 'coloa3'">
                                                <i class="el-icon-time"></i>
                                                {{ formatDate(element.end_date) }}
                                                </span>
                                                <span v-if="element.begin_date" class="data-deal-task-start" :class="dayDiff(element) >= 0 ? 'coloa2' : 'coloa3'">
                                                    <i class="el-icon-date"></i>
                                                    {{dayDiff(element)}}
                                                </span>

                                                <span class="check-task" v-if="element.allMiniTasks > 0">
                                                    <i class="icon-check-task"></i>
                                                    <span>{{ element.checkedMiniTasks }}/{{ element.allMiniTasks }}</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="metka-r">
                                            <span :class="'color_'+label.color_id" v-for="label in element.labels" :key="label.id"></span>
                                        </div>
                                        </div>
                                    </li>
                                    </transition-group>
                                </draggable>
                                </div>
                            </div>
                        </draggable>
                    </div>
                </div>
            </div>

        <!-- Create New Task -->
        <el-dialog
            class="create-task-modal"
            :title="form.id ? $t('message.update_board') : $t('message.create_board')"
            :visible.sync="createNewBoard"
            width="30%"
        >
            <div class="task-form">
                <el-form
                    ref="form"
                    :model="form"
                    :rules="rules"
                    label-position="top"
                >
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item
                                :label="$t('message.name')"
                                class="label_mini"
                                prop="name"
                                >
                                <el-input
                                    :placeholder="$t('message.name')"
                                    v-model="form.name"
                                    size="medium"
                                    autocomplete="off"
                                ></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :span="24">
                            
                            <el-form-item
                                :label="$t('message.color')"
                                class="label_mini"
                                prop="color"
                                >
                                <div class="color-picker-wrap d-flex">
                                    <el-color-picker
                                        v-model="form.color"
                                        @active-change="changeColor"
                                    ></el-color-picker>
                                    <div class="picker-box ml-4 rounded w-100 p-1">
                                        <div
                                            class="h-100 rounded-sm"
                                            :style="{ background: form.color }"
                                        ></div>
                                    </div>
                                </div>
                            </el-form-item>

                        </el-col>

                        <el-col :span="24">
                            <el-form-item :label="$t('message.filials')" class="label_mini" prop="filial_id">
                                <select-filial
                                    :size="'medium'"
                                    :placeholder="$t('message.filial')"
                                    :id="form.filial_id"
                                    v-model="form.filial_id"
                                >
                                </select-filial>
                            </el-form-item>
                        </el-col>

                    </el-row>
                </el-form>
                <el-button
                    class="mt-4"
                    type="primary"
                    :icon="form.id ? 'el-icon-edit' : 'el-icon-circle-plus-outline'"
                    size="medium"
                    @click="submit(true)"
                    >{{ form.id ? $t("message.update") : $t("message.create") }}</el-button
                >
            </div>
        </el-dialog>
        <!-- End Create New Task -->
        
        <div class="app-modal app-modal__full">
            <el-dialog 
                :visible.sync="appModal"
                ref="drawerCreate"
                @opened="drawerOpened('drawerCreateChild')"
                @closed="drawerClosed('drawerCreateChild')" 
                :fullscreen="true">
                <div
                    @click="appModal = false"
                    class="close-modal-stick d-flex align-center p-fixed text-white p-2 font-bold pointer transition"
                >
                    <div class="close-modal-icon text-center rounded-circle transition">
                    <i class="el-icon-close"></i>
                    </div>
                    <span class="close-modal-text text-uppercase ml-2"></span>
                </div>
                <AppModal 
                    :board_id="board_id"
                    ref="drawerCreateChild"
                    drawer="drawerCreate"
                />
            </el-dialog>

            <el-dialog 
                :visible.sync="taskUpdateDrawer"
                ref="drawerUpdate"
                @opened="drawerOpened('drawerUpdateChild')"
                @closed="drawerClosed('drawerUpdateChild')" 
                :fullscreen="true">
                <div
                    @click="taskUpdateDrawer = false"
                    class="close-modal-stick d-flex align-center p-fixed text-white p-2 font-bold pointer transition"
                >
                    <div class="close-modal-icon text-center rounded-circle transition">
                    <i class="el-icon-close"></i>
                    </div>
                    <span class="close-modal-text text-uppercase ml-2"></span>
                </div>
                <AppModalUpdate 
                    :selectedItem="selectedItem"
                    ref="drawerUpdateChild"
                    drawer="drawerUpdate"
                />
            </el-dialog>
        </div>
        </div>
    </div>
</template>

<script>
import draggable from "vuedraggable";
import AppModal from "./components/add-modal";
import AppModalUpdate from "./components/add-modal-update";
import { mapGetters, mapActions, mapState } from "vuex";
import list from "@/utils/mixins/list";
import form from "@/utils/mixins/form";
import drawer from "@/utils/mixins/drawer";
import selectUser from "@/components/inventory/select-deal-users";
import selectFilial from "@/components/inventory/select-filial";
import selectFilter from "@/components/inventory/select-filter";


export default {
  name: "tasks",
  mixins: [list, form, drawer],
  components: {
    draggable,
    AppModal,
    AppModalUpdate,
    selectUser,
    selectFilial,
    selectFilter
  },
  data() {
    return {
      board_id: null,
      appModal: false,
      titleChangedRow: null,
      colorPicker: "#409EFF",
      taskName: "",
      newTaskTitle: "",
      createNewBoard: false,
      editTaskTitleModal: false,
      taskUpdateDrawer: false,
      rows: [],
      drag: false,
      color: "#409EFF", 
    };
  },
  
  watch: {
    list: {
        handler: function(newVal, oldVal) {
          this.rows = JSON.parse(JSON.stringify(this.list));
        },
        deep: true,
        immediate: true,
    },
  },

  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    ...mapGetters({
      list: "taskBoards/list",
      columns: "taskBoards/columns",
      pagination: "taskBoards/pagination",
      filter: "taskBoards/filter",
      sort: "taskBoards/sort",
      model: "taskBoards/model",
      rules: "taskBoards/rules",
      permissions: "auth/permissions",
      authUser: "auth/user",
      mode: "MODE"
    }),
  },
  methods: {
    changeTaskIndex: function (evt, row) {
      if (evt.moved || evt.added) {
        let event = "";
        if (evt.moved) {
          event = evt.moved;
        }
        if (evt.added) {
          event = evt.added;
        }
        this.UpdateTaskIndex({id: event.element.id, task_board_id: row.id, index: event.newIndex})
      }
    },
    dayDiff(element) {
      let finish_date = new Date(element.finish_date);
      let current_date = new Date();
      let end_date = new Date(element.end_date);
      if (!isNaN(current_date) && !isNaN(end_date)) {
        if(!isNaN(finish_date) && finish_date.getFullYear() !== 1970){
         let timeDifference = Math.round((end_date.getTime() - finish_date.setHours(0,0,0))/(1000 * 3600 * 24));    
         return timeDifference;
        }else{
         let timeDifference = Math.round((end_date.getTime() - current_date.setHours(0,0,0))/(1000 * 3600 * 24));    
         return timeDifference;
        }
      } else {
        console.log("Enter valid date.");
      }
    },
    changeBoardIndex: function (event) {
      this.UpdateBoardIndex({id: event.moved.element.id, index: event.moved.newIndex});
    },
    ...mapActions({
      save: "taskBoards/store",
      updateTask: "taskBoards/update",
      updateList: "taskBoards/index",
      setPagination: "taskBoards/setPagination",
      updateSort: "taskBoards/updateSort",
      updateFilter: "taskBoards/updateFilter",
      updateColumn: "taskBoards/updateColumn",
      updatePagination: "taskBoards/updatePagination",
      show: "taskBoards/show",
      empty: "taskBoards/empty",
      delete: "taskBoards/destroy",
      refreshData: "taskBoards/refreshData",
      UpdateBoardIndex: "taskBoards/changeIndex",
      UpdateTaskIndex: "tasks/changeIndex",
      showTask: "tasks/show",
    }),

    fetchData() {
        const query = {...this.filter, ...this.pagination, ...this.sort,};
        if (!this.loadingData) {
            this.loadingData = true;
            this.updateList(query).then(res => {
                this.loadingData = false
            }).catch(err => {
                this.loadingData = false
            });
        }
    },
    newTaskBoard(){
        this.form = JSON.parse(JSON.stringify(this.model)); 
        this.createNewBoard = true;
    },
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          if(this.form.id){
            this.updateTask(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.createNewBoard = false;
              this.fetchData();
              this.form = {};
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
          }else{
            this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.createNewBoard = false;
              this.fetchData();
              this.form = {};
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
          }
        }
      });
    },
    
    openDrawer(board_id) {
      this.board_id = board_id
      this.appModal = !this.appModal;
    },
    openTaskUpdate(task){
        this.selectedItem = task;
        this.taskUpdateDrawer = true;
    },
    closeDrawer(drawer) {       
        this.appModal = false;
        this.taskUpdateDrawer = false;
        if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
            this.$refs[drawer].closeDrawer();
        }
    },
    destroy(model) { 
      this.$confirm(
        this.$t('message.do_you_want_to_delete_board'),
        this.$t('message.warning'),
        {
          confirmButtonText: this.$t('message.yes'),
          cancelButtonText: this.$t('message.no'),
          type: "warning",
        })    
        .then(() => {
            this.delete(model.id)
                .then(res => {
                    if(res.data.result.success){
                        this.fetchData()
                        this.$alert(res);
                    }else{
                        this.$warning_message(res);
                    }
                })
                .catch(err => {
                    this.$alert(err);
                })
        })
    },
    editTaskTitle(row) {
      this.createNewBoard = true;
      let data = JSON.parse(JSON.stringify(row));
      delete data.tasks;
      this.form = data;
    },
    changeColor(val) {
        this.form.color = val;
    },
  },
};
</script>

<style>
.coloa3 {
   background: #ff3f3f;
}
.container__tasks {
    width: 100% ;
    margin: 0 !important;
}
.test__width {
    width: 100% !important;
}
</style>
